<template>
  <div
    :style="{ height: screeHeight + 'px' }"
    style="background-color: #F7F7F7"
  >
    <van-search
      v-model="searchValue"
      shape="round"
      background="#FF4920"
      placeholder="搜索商品 景点"
      show-action
      @focus="toSearch"
    >
      <template #action>
        <div
          @click="toPerson"
          style="font-size: 25px; line-height: 20px"
        >
        <img src="../../assets/person.png" style="width: 29px;">
          <!-- <van-icon name="manager" /> -->
        </div>
      </template>
    </van-search>
    <myList :list="list" :height="60"/>
  </div>
</template>
<script>
import myList from "../../components/indexList";
// import { list } from "../../data.js";
export default {
  name: "index",
  components: {
    myList,
  },
  data() {
    return {
      searchValue: "",
      screenWidth: document.documentElement.clientWidth, // 屏幕宽
      screeHeight: document.documentElement.clientHeight, // 屏幕高
      list: [],
    };
  },
  created() {
    // this.$router.push({path:'/href'})
      this.queryList()
  },
  mounted() {
    // this.$router.push({path:'/href'})
  },
  methods: {
    queryList() {
        this.$http({
            method: "POST",
            url: `${this.apis.groupSubOrgList}?orgId=${this.mydata.orgId}&isSales=0`,
            withCredentials: true,
        }).then((res) => {
            if (res.code == 200) {
                this.list = res.data;
                this.$store.commit('saveIndexList',res.data) //存给搜索页面
            }
        });
    },
    toPerson(){
      console.log("personal")
      try{
        this.$router.push('/personal')
      }catch(e){
        console.log(e)
      }
      
    },
    toInfo(item) {
      this.$router.push({ path: "/info", query: item });
    },
    toSearch() {
      this.$router.push("/search");
    },
    onSearch() {},
  },
};
</script>